<template>
    <div class="goods-info-content">
        <!-- <el-scrollbar class="content-body"> -->
        <div class="goods-info">
            <el-scrollbar class="content-body">
                <div class="g-info-title" style="border-bottom: 1px solid #EEEEEE">
                    <div class="g-text">商品信息</div>
                    <div class="g-item">市场价</div>
                    <div class="g-item">销售价</div>
                    <div class="g-item" style="text-align: center;">操作</div>
                </div>
                <div class="g-content-fillin" v-for="(item, index) in goodsList" :key="index">
                    <div class="g-content">
                        <div class="t-ginfo">
                            <img style="width: 60px; height: 60px; padding-top: 6px" :src="item.good_images" alt="">
                            <el-tooltip placement="top"
                                :content="item.good_title">
                                <div class="g-title">{{  item.good_title }}</div>
                            </el-tooltip>
                        </div>
                        <div class="t-item">{{ item.market_price }}</div>
                        <div class="t-item">{{ item.sales_price }}</div>
                        <div class="t-item t-parse">
                            <el-link type="success" :underline="false" v-if="status == false" @click="fillIn(index)">填写信息</el-link>
                            <el-link type="success" :underline="false" v-if="status == true" @click="fillIn(index)">编辑信息</el-link>
                            <el-link type="success" :underline="false" @click="seeKeyWord(item.hot_keywords_id)">查看关键词</el-link>
                        </div>
                    </div>
                    <div class="g-fillin" v-if="keys.indexOf(index) > -1">
                        <div class="item">
                            <div class="item-text">
                                <img src="../../../../assets/images/student/subordinate.png" alt="">
                                <div style="margin-left: 20px">请写出搜索指数排名前{{ item.search_rank }}的关键词：</div>
                            </div>
                            <div class="item-input">
                                <div v-for="(value, index) in item.searchRank" :key="index">
                                    <el-input
                                        v-model="item.searchRank[index]"
                                        :min="1"
                                        :max="100"
                                        placeholder="请填写关键词信息"
                                        style="width: 220px; margin-left: 30px; margin-top: 15px;"
                                    ></el-input>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="item-text">
                                <img src="../../../../assets/images/student/subordinate.png" alt="">
                                <div style="margin-left: 20px">请写出点击指数排名前{{ item.click_rank }}的关键词：</div>
                            </div>
                            <div class="item-input">
                                <div v-for="(value, index) in item.clickRank" :key="index">
                                    <el-input
                                        v-model="item.clickRank[index]"
                                        :min="1"
                                        :max="100"
                                        placeholder="请填写关键词信息"
                                        style="width: 220px; margin-left: 30px; margin-top: 15px;"
                                    ></el-input>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="item-text">
                                <img src="../../../../assets/images/student/subordinate.png" alt="">
                                <div style="margin-left: 20px">请写出点击率排名前{{ item.click_rate_rank }}的关键词：</div>
                            </div>
                            <div class="item-input">
                                <div v-for="(value, index) in item.clickRateRank" :key="index">
                                    <el-input
                                        v-model="item.clickRateRank[index]"
                                        :min="1"
                                        :max="100"
                                        placeholder="请填写关键词信息"
                                        style="width: 220px; margin-left: 30px; margin-top: 15px;"
                                    ></el-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-scrollbar>
        </div>
        <!-- </el-scrollbar> -->
        <div class="goods-operation">
            <div class="goods-button">
                <el-button @click="reset">重置</el-button>
                <el-button type="primary" @click="preserve">保存</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import { stuOpStuOpHotGoodsList, goodsTextWriteStuSaveAnswer, goodsTextWriteStuGetAnswer, goodsTextWriteRemoveStuAnswer, stuOpStuOpGoodsList, stuOpStuOptimizeTitle, stuOpStuOpGoodsDel } from '@/utils/apis.js'
    export default {
        name: "GoodsText",
        data() {
            return {
                //商品列表
                goodsList: [],
                keys: [],
                keysArr: [],
                status: false,
            }
        },
        mounted() {
            this.getHostGoodsList();
            setTimeout(() => {
                this.getStuAnswer();
            }, 500)
        },
        methods: {
            //商品数据
            getHostGoodsList() {
                let param = {
                    page: '0',
                }
                stuOpStuOpHotGoodsList(param).then((res) => {
                    for (let x = 0; x < res.data.list.length; x ++) {
                        res.data.list[x].good_images = localStorage.getItem('hosturl') + res.data.list[x].good_images;
                        res.data.list[x].searchRank = new Array(res.data.list[x].search_rank);
                        res.data.list[x].clickRank = new Array(res.data.list[x].click_rank);
                        res.data.list[x].clickRateRank = new Array(res.data.list[x].click_rate_rank);
                    };
                    this.goodsList = res.data.list;
                }).catch((err) => {
                    console.log(err);
                })
            },
            //填写信息
            fillIn(index) {
                // this.keys.push(index);
                this.keysArr.push(index);
                this.keys = Array.from(new Set(this.keysArr));
            },
            //查看关键词
            seeKeyWord(id) {
                let routeUrl = this.$router.resolve({
                    path: '/student/examing/goodsInfo/hotKeyword',
                    name: 'studentExamingGoodsInfoHotKeyword',
                    query: {
                        id: id,
                    }
                })
                window.open(routeUrl.href, "_blank");
            },
            //重置
            reset() {
                goodsTextWriteRemoveStuAnswer().then(res => {
                    if (res.code == 200) {
                        this.status = false;
                        this.$message.success(res.msg);
                        this.getHostGoodsList();
                    }
                })
                // for (let x = 0; x < this.goodsList.length; x ++) {
                //     this.goodsList[x].searchRank = [];
                //     this.goodsList[x].clickRank = [];
                //     this.goodsList[x].clickRateRank = [];
                //     this.goodsList[x].searchRank = new Array(this.goodsList[x].search_rank);
                //     this.goodsList[x].clickRank = new Array(this.goodsList[x].click_rank);
                //     this.goodsList[x].clickRateRank = new Array(this.goodsList[x].click_rate_rank);
                // }
            },
            //保存
            preserve() {
                for (let x = 0; x < this.goodsList.length; x ++) {
                    for (let i = 0; i < this.goodsList[x].searchRank.length; i ++) {
                        if (!this.goodsList[x].searchRank[i]) {
                            this.$message.warning('请填写搜索指数排名关键词！');
                            this.keys.push(x)
                            return;
                        }
                    };
                    for (let i = 0; i < this.goodsList[x].clickRank.length; i ++) {
                        if (!this.goodsList[x].clickRank[i]) {
                            this.$message.warning('请填写搜索指数排名关键词！');
                            this.keys.push(x)
                            return;
                        }
                    };
                    for (let i = 0; i < this.goodsList[x].clickRateRank.length; i ++) {
                        if (!this.goodsList[x].clickRateRank[i]) {
                            this.$message.warning('请填写搜索指数排名关键词！');
                            this.keys.push(x)
                            return;
                        }
                    };
                };
                let param = [];
                if (this.status == false) {
                    for (let x = 0; x < this.goodsList.length; x ++) {
                        let obj = {id: '', hot_goods_id: '', hot_keywords_id: '', search_answer: '', click_answer: '', click_rate_answer: ''};
                        obj.hot_goods_id = this.goodsList[x].hot_goods_id;
                        obj.hot_keywords_id = this.goodsList[x].hot_keywords_id;
                        obj.search_answer = this.goodsList[x].searchRank;
                        obj.click_answer = this.goodsList[x].clickRank;
                        obj.click_rate_answer = this.goodsList[x].clickRateRank;
                        param.push(obj);
                    };
                    goodsTextWriteStuSaveAnswer({stu_answer: param}).then(res => {
                        if (res.code == 200) {
                            this.status = true;
                            this.$message.success(res.msg);
                            this.keys = []
                            // this.$router.push('/student/examing/goodsInfo/index');
                        };
                    })
                } else {
                    for (let x = 0; x < this.goodsList.length; x ++) {
                        let obj = {id: '', hot_goods_id: '', hot_keywords_id: '', search_answer: '', click_answer: '', click_rate_answer: ''};
                        obj.id = this.goodsList[x].id
                        obj.hot_goods_id = this.goodsList[x].hot_goods_id;
                        obj.hot_keywords_id = this.goodsList[x].hot_keywords_id;
                        obj.search_answer = this.goodsList[x].searchRank;
                        obj.click_answer = this.goodsList[x].clickRank;
                        obj.click_rate_answer = this.goodsList[x].clickRateRank;
                        param.push(obj);
                    };
                    goodsTextWriteStuSaveAnswer({stu_answer: param}).then(res => {
                        if (res.code == 200) {
                            this.$message.success(res.msg);
                            this.keys = []
                            // this.$router.push('/student/examing/goodsInfo/index');
                        };
                    })
                }
                
            },
            //编辑回显
            getStuAnswer() {
                goodsTextWriteStuGetAnswer().then(res => {
                    if (res.code == 200) {
                        if (res.data != '') {
                            this.status = true;
                            for (let x = 0; x < this.goodsList.length; x ++) {
                                for (let i = 0; i < res.data.length; i ++) {
                                    if (this.goodsList[x].hot_goods_id == res.data[i].hot_goods_id) {
                                        // console.log(123);
                                        this.goodsList[x].id = res.data[i].id;
                                        this.goodsList[x].clickRank = res.data[i].click_answer;
                                        this.goodsList[x].clickRateRank = res.data[i].click_rate_answer;
                                        this.goodsList[x].searchRank = res.data[i].search_answer;
                                    };
                                };
                            };
                        };
                    };
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .goods-info-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0 20px;
        .content-body {
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
                padding-bottom: 15px;
                .el-scrollbar__view {
                    height: 100%;
                }
            }
        }
        .goods-info {
            flex: 4;
            display: flex;
            flex-direction: column;
            margin-top: 40px;
            overflow: hidden;
            .g-info-title {
                display: flex;
                flex-direction: row;
                height: 50px;
                font-size: 16px;
                color: #333;
                line-height: 50px;
                background: #F6F6F6;
                .g-text {
                    flex: 1.6;
                    margin-left: 39px;
                }
                .g-item {
                    flex: 1;
                }
            }

            .g-content-fillin {
                display: flex;
                flex-direction: column;
                .g-content {
                    display: flex;
                    flex-direction: row;
                    height: 70px;
                    background: #FBFBFD ;
                    border-bottom: 1px solid #EEEEEE;
                    .t-ginfo {
                        flex: 1.6;
                        display: flex;
                        flex-direction: row;
                        height: 70px;
                        margin-left: 39px;
                        font-size: 16px;
                        color: #333333;
                    }
                    .t-item {
                        flex: 1;
                        height: 70px;
                        font-size: 16px;
                        color: rgba(51, 51, 51, .8);
                        line-height: 70px;
                    }
                    .g-title {
                        height: 44px;
                        margin-left: 8px;
                        line-height: 70px;
                        display:-webkit-box;
                        text-overflow:ellipsis;
                        overflow:hidden;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient:vertical;
                        // padding-top: 13px;
                    }
                    .t-parse {
                        text-align: center;
                    }
                }
                .g-fillin {
                    display: flex;
                    flex-direction: column;
                    .item {
                        display: flex;
                        flex-direction: row;
                        border-bottom: 1px solid #EEEEEE;
                    }
                    .item-text {
                        flex: 1;
                        height: 22px;
                        display: flex;
                        flex-direction: row;
                        margin: 24px 0 24px 63px;
                    }
                    .item-input {
                        flex: 4.6;
                        display: flex;
                        flex-wrap: wrap;
                        margin: 0 27px 15px 0;
                    }
                }
            }
        }
        .goods-operation {
            flex: 1;
            display: flex;
            justify-content: center;
            margin-top: 140px;
        }
    }
</style>